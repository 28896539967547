import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 获取扫码信息列表
 */
export const getScancodeList = (params) => get('/tradeapp/trace/user/record/list', params)

/**
 * 批量删除扫码信息
 */
export const deleteScancode = (params) => del('/tradeapp/trace/user/record', params)

/**
 * 批量导出扫码信息
 */
export const exportScancode = (params) => get('/tradeapp/trace/user/record/download', params)